import { LoadingButton } from '@mui/lab';
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import useAuth from '#/hooks/useAuth';
import useLocales from '#/hooks/useLocales';

type SubscribeButtonProps = {
  productId: string;
  partnerProductId: string;
  onStripeSession?: () => void;
  isLoading?: boolean;
  disabled?: boolean;
};

export default function ManagePurchaseButton({
  productId,
  partnerProductId,
  onStripeSession,
  isLoading,
  disabled,
}: SubscribeButtonProps) {
  const { user } = useAuth();
  const theme = useTheme();
  const { translate } = useLocales();

  const isPurchased =
    user?.subscription_package === productId ||
    user?.subscription_package === partnerProductId;

  return (
    <LoadingButton
      sx={{
        display: 'inline-block',
        background: isPurchased
          ? `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`
          : theme.palette.primary.main,
        backgroundSize: ' 200% 100%',
        backgroundPosition: ' -100% 0',
        transition: 'background-position 1s ease, transform 0.3s ease',
        ':hover': {
          backgroundPosition: ' 100% 0',
          transform: 'scale(1.05)',
        },
      }}
      aria-label={String(translate('provisionLanding.pricing.manageButton'))}
      onClick={onStripeSession}
      variant="contained"
      loading={isLoading}
      disabled={disabled}
    >
      <Typography variant="body1">
        {isPurchased
          ? String(translate('provisionLanding.pricing.manageButton'))
          : String(translate('provisionLanding.pricing.purchaseButton'))}
      </Typography>
    </LoadingButton>
  );
}
