import { Box, Typography } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import useLocales from '#/hooks/useLocales';

type Props = {
  price: string | undefined;
  salePrice: string | undefined;
  promoLabel?: boolean;
  isFromFinFinder?: boolean;
  finfinderSale?: string;
  productId?: string;
};

export default function PriceLabel({
  price,
  salePrice,
  promoLabel,
  isFromFinFinder,
  finfinderSale,
  productId,
}: Props) {
  const { translate } = useLocales();

  return (
    <Box>
      <Typography variant="body2" color="text.secondary">
        <span
          style={{
            fontWeight: 'bold',
            fontSize: '20px',
          }}
        >
          CHF {price}{' '}
        </span>
        {/* {promoLabel && (
          <span
            style={{
              textDecoration: 'line-through',
              color: 'red',
              marginRight: '5px',
              marginTop: '-15px',
              position: 'absolute',
              opacity: 0.5,
              fontWeight: 'bold',
            }}
          >
            CHF {salePrice}
          </span>
        )} */}
        {' / '}
        {productId === 'family' &&
          `${String(
            translate('provisionLanding.pricing.additionalMember')
          )} / `}

        {String(translate('provisionLanding.pricing.perYear'))}
        {/* {isFromFinFinder && (
          <>
            <br />
            <span>
              {highlightWords(String(translate(finfinderSale)), ['200', '400'])}
            </span>
          </>
        )} */}
      </Typography>
    </Box>
  );
}

function highlightWords(
  sentence: string,
  targetPhrases: string[],
  setActiveStep?: Dispatch<SetStateAction<number>>
): React.ReactNode[] {
  let elements: React.ReactNode[] = [sentence];
  targetPhrases.forEach((phrase, i) => {
    elements = elements.flatMap((el, j) => {
      if (typeof el === 'string') {
        const parts = el.split(new RegExp(`(${phrase})`, 'gi'));
        return parts.map((part, k) => {
          if (part.toLowerCase() === phrase.toLowerCase()) {
            if (phrase.toLowerCase() === 'here') {
              return (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                <span
                  key={`${i}-${j}-${k}`}
                  style={{ color: '#3366FF', cursor: 'pointer' }}
                  onClick={() => setActiveStep && setActiveStep(3)}
                >
                  {part}
                </span>
              );
            }
            return (
              <span key={`${i}-${j}-${k}`} style={{ color: '#3366FF' }}>
                {part}
              </span>
            );
          }
          return part;
        });
      }
      return el;
    });
  });
  return elements;
}
